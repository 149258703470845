.callDie
{
  flex: none;
  width: 2.5vmax;
  height: 2.5vmax;
  display: inline-block;
  transform: translateY(6px);
}
.counterDie
{
  flex: none;
  width: 2.5vmax;
  height: 2.5vmax;
  box-sizing: border-box;
  border-radius: .5vh;
  position:relative;
}

@keyframes box-shadow-pulsate-palafico {
  0%   { box-shadow: 0 0 0 5px rgb(255, 60, 0); }
  50% { box-shadow: 0 0 0 5px rgba(255, 60, 0, 0.2); }
  100% { box-shadow: 0 0 0 5px rgba(255, 60, 0); }
}

.counterDie.palafico
{
  animation: box-shadow-pulsate-palafico 2s infinite;
}

/*media for ultrawide*/
@media (min-width: 2320px) {
  .counterDie, .callDie
  {
    width: 2vw;
    height: 2vw;
  }
}

.centerText, .centerCounter
{
  transition-property: opacity transform;
  opacity: 0; /* Start with the element hidden */
  font-size: 2vmax;
  font-weight: bold;
  width: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.centerText{
  transition-duration: 2s;
}

.centerCounter
{
  transition-duration: 1s;
}
.centerCounter > * {
  margin-right: 10px; /* Adjust the gap as needed */
}

.centerText.visible
{
  transform: translateY(-10px); /* Move element up */
  position: relative;
}

.visible {
    opacity: 1;
}

.popup {
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column; /* This will stack children vertically */
    align-items: center; 
    gap: 1vh;
    padding: 1vw;;
}

.popup-die
{
    width: 1.3vw;
}
.popup-row
{
    display: flex;
    flex-direction: row;
}