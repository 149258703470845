.friends-header
{
    font-size: large;
    font-weight: 750;
}

.btn-check{
    background: none;
    pointer-events: auto;
    border: none;
    padding: 0;
    cursor: pointer;
}

.btn-join{
    background: none;
    pointer-events: auto;
    border: none;
    padding: 0;
    cursor: pointer;
}

#send-request-group
{
    margin-bottom: 0;
}

.friend-card {
    /* Other styles for friend card */
    padding: .4vw; /* Adjust padding as needed */
    display: inline-block;
    min-width: 20vw;
}
.friends-div
{
    max-height: 90vh;
    width: 40vw;
    display: flex;
    flex-direction: column;
}

.friends-list-container {
    max-height: 90%; /* Adjust as needed */
    width: 60%;
    overflow-y: auto; /* Enables vertical scrolling */
    padding: 10px 0; /* Padding at top and bottom of the container */
}

.inverse-feature-card.friend-card {
    height: 4.5vh;
    margin-bottom: .4vh; /* Spacing between cards */
    position: relative; /* Position relative for absolute child positioning */
}

.friend-card span, .friend-card button {
    position: absolute; /* Absolute positioning within the card */
    top: 50%; /* Vertically center */
    transform: translateY(-50%); /* Adjust for exact centering */
}

.friend-card span {
    left: 1vw; /* Align the username to the left */
}

.friend-card button {
    right: 1vw; /* Align the status/button to the right */
}
.actions
{
    position: absolute;
    top: 50%; /* Vertically center */
    transform: translateY(-50%);
    right: 10px;
}

/* Styles for the scrollbar track (background) */
.friends-list-container::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Light gray track */
    border-radius: 10px; /* Rounded corners for the track */
}

/* Styles for the scrollbar handle */
.friends-list-container::-webkit-scrollbar-thumb {
    background-color: #888; /* Darker gray handle */
    border-radius: 10px; /* Rounded corners for the handle */
}

/* Handle on hover */
.friends-list-container::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Even darker gray on hover */
}

/* Optionally set the width of the scrollbar (default is around 16px) */
.friends-list-container::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

.online-circle
{
    opacity: .8;
    transform: translateY(13%);
    margin-left: .4vw;
}

.divider
{
    padding: 1vw;
}

#friends-input
{
    width: 20vw;
}
.friends-search
{
    width: 25vw;
    margin-bottom: 2vh;
}

.friend-card.request
{
    background-color: rgb(146, 168, 176, .8);
    color: white;
}

.in-game
{
    font-size: .7vmax;
    position:absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    color:#078da8;
    font-weight: 700;
}

#accept-text
{
    position:absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 70%;
}

.join-game-button
{
    color: green;
    width: 1vmax;
    height: 1vmax;
}
.accept-friend-button
{
    color: green;
    width: 1vmax;
    height: 1vmax;
}

/*media for mobile portrait*/
@media (max-width: 992px) and (orientation: portrait) {
    .friends-div
    {
        width: 90vw;
    }
    .friends-list-container
    {
        width: 100%;
    }
    .friend-card
    {
        min-width: 100%;
    }
    #friends-input
    {
        width: 20vw;
    }
    #accept-text
    {
        left: 60%;
    }
    .join-game-button
    {
        width: 2.5vmax;
        height: 2.5vmax;
    }
    .accept-friend-button
    {
        width: 2.5vmax;
        height: 2.5vmax;
    }
    .friends-search
    {
        width: 65vw;
        margin-bottom: 1vh;
    }
    .in-game
    {
        font-size: 1.5vmax;
    }
}
/*media for mobile landscape*/
@media (max-width: 992px) and (orientation: landscape) {
    .friends-div
    {
        width: 90vw;
    }
    .friends-list-container
    {
        width: 100%;
    }
    .friend-card
    {
        min-width: 100%;
    }
    #friends-input
    {
        width: 20vw;
    }
    #accept-text
    {
        left: 60%;
    }
    .join-game-button
    {
        width: 2.5vmax;
        height: 2.5vmax;
    }
    .accept-friend-button
    {
        width: 2.5vmax;
        height: 2.5vmax;
    }
    .friends-search
    {
        width: 35vw;
        margin-bottom: 1vh;
    }
    .in-game
    {
        font-size: 1.5vmax;
    }
}