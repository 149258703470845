/*controls css*/
.controls
{
  background-color: rgb(29,27,34, .8);
  border-radius: 8px;
  display: inline-block;
  padding: 1% 1%;
  position: absolute;
  bottom: 5%;
  right: 3%;
  width: 16%;
  height: 27%;
}

.lobby-controls
{
  display: flex;
  flex-direction: column;
  gap : 2.8vh;
  justify-content: center;
  height: 100%;
}

/*media for ultrawide*/
@media (min-width: 2320px) {
    .controls
    {
      height: 33%;
    }
}

/*media for mobile landscape*/
@media (max-width: 992px) and (orientation: landscape) {
    .controls
    {
      height: 70%;
      width: 25%;
      right: 1%;
      bottom: -15%;
    }
    .controls.full-screen
    {
      height: 53%;
      width: 25%;
      right: 1%;
      bottom: 5%;
    }
}

/*media for mobile portrait*/
@media (max-width: 992px) and (orientation: portrait) {
    .controls
    {
      height: 12%;
      width: 90%;
      bottom: -5%;
      right: 5%
    }
    .lobby-controls
    {
      flex-direction: row;
      gap: 2vw;
      align-items: center;
    }
}

.controls-disabled > *:not(:has(>.btn-calza)) {
  opacity: 0.5;
  pointer-events: none;
}

.controls-disabled > :has(>.btn-calza) > :not(.btn-calza) {
  opacity: 0.5;
  pointer-events: none;
}

.btn-bid
{
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  width: 7vw;
  height: 6vh;
}

.btn-call
{
  width:7vw;
  height:7vh;
  position: absolute;
  bottom:5%;
  right:20%;
  background-color: rgb(29,27,34, .8);
}

.btn-calza
{
  width: 7vw;
  height: 7vh;
  position: absolute;
  bottom:15%;
  right:20%;
  background-color: rgb(29,27,34, .8);
}

.lobby-button
{
  height: 14vh;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5vw;
  font-weight: 700;
  color: white;
  cursor: pointer;
}

/*media for mobile*/
@media (max-width: 992px) and (orientation: landscape) {
    .btn-bid, .btn-calza, .btn-call
    {
      height: 14vh;
      width: 12vw;
    }
    .btn-calza
    {
      bottom: 60%;
      right: 14%;
    }
    .btn-call
    {
      bottom: 60%;
      right: 1%;
    }
}

/*media for mobile portrait*/
@media (max-width: 992px) and (orientation: portrait) {
  .lobby-button
  {
    width: 25vw;
    height: 6vh;
    font-size: calc(8px + 1vh);
  }
}

.bid-number .count {
  color: #000;
  display: inline-block;
  vertical-align: top;
  font-size: 2vw;
  font-weight: 700;
  line-height: 2vw;
  padding: 0 2px
  ;min-width: 3vw;
  text-align: center;
}

.bid-number {
  position: absolute;
  top: 0%;
  padding-top: 33%;
  width: 50%;
  height: 100%;
}

/* Default styles for plus and minus buttons */
.bid-number .plus,
.bid-number .minus {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  color: white;
  width: 2vw;
  height: 2vw;
  font: 2vw/1 Arial, sans-serif;
  text-align: center;
  border-radius: 50%;
}

.bid-number > span{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.bid-number > input{  
  border: 0;
  width: 2%;
  border-radius: 5px;
}
nput::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bid-number > input:disabled{
  background-color:white;
}

div {
  text-align: center;
}
.minus:hover{
  background-color: #717fe0 !important;
}
.plus:hover{
  background-color: #717fe0 !important;
}

/*media for mobile landscape*/
@media (max-width: 992px) and (orientation: landscape) {
  .bid-number
  {
    left: -3%;
    width: 60%;
  }
  .bid-number .count {
    font-size: 3.5vw;
    line-height: 3.5vw;
    padding: 0 2px;
    min-width: 5vw;
  }
  .bid-number .plus,
  .bid-number .minus {
    width: 4vw;
    height: 4vw;
    font: 4vw/1 Arial, sans-serif;
  }
  .bid-number > input{  
    width: 4%;
  }
}

.control-half-2
{
  width:50%;
  height:100%;
  position: absolute;
  padding-top: 28%;
  top: 0%;
  left: 50%;
}

.die-menu-container {
  position: absolute; /* or relative, depending on your layout needs */
  width: 3.3vw; /* Width of the img tag */
  height: 3.3vw; /* Height of the img tag */
  /* Adjust left and top as needed to position on your page */
  left: 10%;
}

#die-menu {
  width:100%;
  height:100%;
  list-style: none;
}

.die-menu-button {
  position: absolute;
  padding: 0;
  left: 0;
  top: 0;
}

.die-menu-button > div {
  width: 3.3vw;
  height: 3.3vw;
  position: absolute;
  left: 0;
  top: 0;
}

.die-menu-item {
  position: absolute;
  top: 1.5vw; /* Align with the center of the button vertically */
  right: 1.5vw; /* Align with the center of the button horizontally */
  transform-origin: 1.75vw center; /* Set origin to the center of the button */
  z-index: -1;
  transition: transform 0.5s;
}

.die-menu-item:not(.disabled):hover{
  opacity: 0.5;
}

.die-menu-item.disabled
{
  opacity: .25;
}

.die-menu-item > div {
  width: 2vw;
  height: 2vw;
  position: absolute;
  left: -1vw;
  top: -1vw;
  transform-origin: center center; /* Center of the image */
}

.control-half-2:hover #die-menu > .die-menu-item:nth-child(1) {
  transform: translateX(3.5vw) translateY(-5.6vw);
  z-index: 0
}
.control-half-2:hover #die-menu > .die-menu-item:nth-child(2) {
    transform: translateX(3.5vw) translateY(-3.3vw);
    z-index:0;
}
.control-half-2:hover #die-menu > .die-menu-item:nth-child(3) {
    transform:translateX(3.5vw) translateY(-1vw);
    z-index:0;
}
.control-half-2:hover #die-menu > .die-menu-item:nth-child(4) {
    transform:translateX(3.5vw) translateY(1.3vw);
    z-index:0;
}
.control-half-2:hover #die-menu > .die-menu-item:nth-child(5) {
  transform:translateX(3.5vw) translateY(3.6vw);
  z-index:0;
}
.control-half-2:hover #die-menu > .die-menu-item:nth-child(6) {
  transform:translateX(3.5vw) translateY(5.9vw);
  z-index:0;
}

.controls-disabled .die-menu-item
{
  opacity:0 ;
}

/* media for mobile landscape*/
@media (max-width: 992px) and (orientation: landscape) {
  .control-half-2
  {
    padding-top: 30.5%;
  }
  .die-menu-button > div {
    width: 5.5vw;
    height: 5.5vw;
  }
  .die-menu-item > div {
    width: 3.5vw;
    height: 3.5vw;
  }

  .control-half-2:hover #die-menu > .die-menu-item:nth-child(1) {
    transform: translateX(5.5vw) translateY(-7.5vw);
    z-index: 0
  }
  .control-half-2:hover #die-menu > .die-menu-item:nth-child(2) {
      transform: translateX(5.5vw) translateY(-3.5vw);
      z-index:0;
  }
  .control-half-2:hover #die-menu > .die-menu-item:nth-child(3) {
      transform:translateX(5.5vw) translateY(.5vw);
      z-index:0;
  }
  .control-half-2:hover #die-menu > .die-menu-item:nth-child(4) {
      transform:translateX(5.5vw) translateY(4.5vw);
      z-index:0;
  }
  .control-half-2:hover #die-menu > .die-menu-item:nth-child(5) {
    transform:translateX(5.5vw) translateY(8.5vw);
    z-index:0;
  }
  .control-half-2:hover #die-menu > .die-menu-item:nth-child(6) {
    transform:translateX(5.5vw) translateY(12.5vw);
    z-index:0;
  }
}
