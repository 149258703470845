.how-to-card {
    max-height: 90%; 
    width: 60%;
    padding: 12px; /* Padding at top and bottom of the container */
}

.how-to-card li 
{
    text-align: left;
    color: rgb(193, 216, 247);
}
.how-to-card ol
{
    width:60%;
    margin: auto;
}

/* media for mobile portrait */
@media (max-width: 992px) and (orientation: portrait) {
    .how-to-card {
        width: 90%;
    }
    .how-to-card ol
    {
        width:90%;
    }
}
/*media for mobile landscape*/
@media (max-width: 992px) and (orientation: landscape) {
    .how-to-card {
        width: 85%;
    }
    .how-to-card ol
    {
        width:85%;
    }
}