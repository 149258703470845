/* Modal Overlay - covers the entire screen with a semi-transparent background */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* ensure it's above other content */
  }
  
  /* Modal Content - the actual box that appears */
  .modal-content {
    position: relative;
    background-color: #fff;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    max-height: 80%;
    overflow-y: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Close Button (X) at the top right */
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
  }

  /* Legal Content: styled for better readability */
  .legal-content {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
    line-height: 1.6;
    color: #444;
    white-space: pre-wrap;
    margin-top: 20px;
    text-align: left;
  }