:root {

  --input-color: #99A3BA;
  --input-border: #CDD9ED;
  --input-background: #fff;
  --input-placeholder: #CBD1DC;

  --input-border-focus: #275EFE;

  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #EEF4FF;

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: #678EFE;

}

input[type=number] {
  -moz-appearance:textfield;
}

.form-field {
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 6px;
  color: var(--input-color);
  border: 1px solid var(--input-border);
  background: var(--input-background);
  transition: border .3s ease;
  &::placeholder {
      color: var(--input-placeholder);
  }
  &:focus {
      outline: none;
      border-color: var(--input-border-focus);
  }
}

.form-group {
  position: relative;
  display: flex;
  width: 100%;
  & > span,
  .form-field {
      white-space: nowrap;
      display: block;
      &:not(:first-child):not(:last-child) {
          border-radius: 0;
      }
      &:first-child {
          border-radius: 6px 0 0 6px;
      }
      &:last-child {
          border-radius: 0 6px 6px 0;
      }
      &:not(:first-child) {
          margin-left: -1px;
      }
  }
  .form-field {
      position: relative;
      z-index: 1;
      flex: 1 1 auto;
      width: 1%;
      margin-top: 0;
      margin-bottom: 0;
  }
  & > span {
      text-align: center;
      padding: 8px 12px;
      font-size: 14px;
      line-height: 25px;
      color: var(--group-color);
      background: var(--group-background);
      border: 1px solid var(--group-border);
      transition: background .3s ease, border .3s ease, color .3s ease;
  }
  &:focus-within {
      & > span {
          color: var(--group-color-focus);
          background: var(--group-background-focus);
          border-color: var(--group-border-focus);
      }
  }
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: inherit;
  &:before,
  &:after {
      box-sizing: inherit;
  }
}

body {
  .form-group {
      max-width: 360px;
      &:not(:last-child) {
          margin-bottom: 32px;
      }
  }
}

.App {
  text-align: center;
}

.app-logo
{
  width: 35%;
  margin-bottom: 1em;
}

/*media for vertical phone screens*/
@media (max-width: 600px) {
  .app-logo
  {
    width: 90%;
  }
}

.app-logo-top
{
  width: 64px;
}


.App-main {
  background-color: #4c5b75;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vw);
  color: white;
  width:100%
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.divider{
  width: 30px;
  height:auto;
  display:inline-block;
}

/* Buttons */
.button{
  appearance: none;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 1vw;
  font-weight: 600;
  line-height: 3vh;
  padding: 0.5vw 1vw;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  margin: 2px;
}

/*media for phone screens*/
@media (max-width: 992px) {
  .button
  {
    font-size: 4vh;
    line-height: 5vh;
    padding: 0.8vw 2vw;
    font-weight: 500;
  }
}

.color-arrow{
  position: absolute;
  top: 50%;
  background: none;
  pointer-events: auto;
  border: none;
  padding: 0;
  cursor: pointer;
  z-index:2;
  transform: translateY(-50%);
}

.color-arrow-icon
{
  width: 3vmin;
  height: 3vmin;
}

.btn-full-screen{
  position: absolute;
  top:3.5%;
  left: 80px;
  background: none;
  pointer-events: auto;
  border: none;
  padding: 0;
  cursor: pointer;
  z-index:2;
  transform: translateY(-50%);
}

/* media for mobile */
@media (max-width: 992px) {
  .color-arrow-icon
  {
    width: 5vmin;
    height: 5vmin;
  }
}

.btn-kick{
  background: none;
  position: absolute;
  pointer-events: auto;
  border: none;
  padding: 0;
  cursor: pointer;
  right: 0%;
  padding: 1vmin;
}


.color-arrow:hover {
  color: rgba(17, 17, 17, 0.6);
}

.btn-info
{
  background-color: #2e6ba4;
}
.btn-info:focus {
  box-shadow: rgba(46, 93, 164, 0.4) 0 0 0 3px;
  outline: none;
}
.btn-info:hover {
  background-color: #275988;
}


.btn-green {
  background-color: #2ea44f;
}

.abort-button
{
  position: absolute;
  top:1.5%;
  left: 80px;
}

.btn-danger
{
  background-color: #d62e22;
}
.btn-danger:focus {
  box-shadow: rgba(99, 2, 2, 0.4) 0 0 0 3px;
  outline: none;
}
.btn-danger:hover {
  background-color: #bb281e;
}
.btn-green
{
  background-color: #2ea44f;
}
.btn-green:focus {
  box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
  outline: none;
}
.btn-green:hover {
  background-color: #288d44;
}

.button:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button:disabled {
  opacity: .5;
  border-color: rgba(27, 31, 35, .1);
  cursor: default;
  pointer-events: none;
}

.button:active
.button.active {
  background-color: lighten(currentColor, 20%);
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}

.host-crown
{
  position: absolute;
  top: -5%;
  left: -6%;
  width: 15%;
  height:15%;
  fill: darkgoldenrod;
  transform: rotate(-45deg);
}

h5 {
  font-family: CerebriSans-Bold, -apple-system, BlinkMacSystemFont, Roboto, sans-serif;
  font-weight: 400;
}

.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  vertical-align: -.125em;
}

.feature-card {
  background-color: #202123;
  border-radius: 8px;
  display: inline-block;
  margin: .25rem 0;
  padding: 4vmin 4vmin;
  align-items: center;
  border: 2px solid rgba(255,255,255,.4);
  border-radius: 1vh;
  box-shadow: 0 0 2px 2px rgba(0,0,0,.85);
  color: #fff;
  font-family: Raleway,sans-serif;
  font-size: large;
}


.mute-button {
  position: relative;
  top: 15%;
  left: 15%;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: rgb(247, 250, 247);
  color: white;
  font-size: 16px;
  cursor: pointer;
}


/* Styles for the scrollbar track (background) */
.chat::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Light gray track */
    border-radius: 10px; /* Rounded corners for the track */
}

/* Styles for the scrollbar handle */
.chat::-webkit-scrollbar-thumb {
    background-color: #888; /* Darker gray handle */
    border-radius: 10px; /* Rounded corners for the handle */
}

/* Handle on hover */
.chat::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Even darker gray on hover */
}

/* Optionally set the width of the scrollbar (default is around 16px) */
.chat::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

.chatBox {
  background-color: rgb(29, 27, 34, 0.8);
  border-radius: 8px;
  padding: 6px 6px 20px 6px;
  position: absolute;
  height: 60%;
  width: 20%;
  top: 5%;
  right: 3%;
  z-index: 5;
}

/*media for ultrawide*/
@media (min-width: 2320px) {
  .chatBox
  {
    height:50%;
  }
}

/*media for mobile*/
@media (max-width: 992px) {
  .chat-box-off
  {
    display: none;
  }
  .chatBox
  {
    width: 90%;
    height: 90%;
    top: 5%;
    right: 4%;
  }
}

.chat {
  display: flex;
  width: 96%;
  flex-direction: column-reverse; /* Reverse the order of items */
  overflow-y: hidden; /* Enable vertical scrolling if messages exceed container height */
  height: calc(100% - 35px); /* Subtract the height of the input field */
  position: absolute;
  top: .5%;
  left: 2%;
}

.chat:focus
{
  overflow-y:auto;
}

.chat span {
  display: block;
  margin-bottom: 0; /* No margin between messages */
  padding: 5px 2px;
  font-size: 15px; /* Adjust the font size */
  max-width: 100%; /* Make sure the text spans the entire width */
  word-wrap: break-word; /* Allow words to break onto the next line if they exceed the container width */
  text-align: left; /* Align text to the left */
}

.chatBox input[type="text"] {
  width: 100%;
  padding: 9px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 12px;
  position: absolute;
  bottom: 0%;
  left: 0%;
}

.inverse-feature-card {
  background-color: rgba(255, 255, 255, .8);
  border-radius: 8px;
  display: inline-block;
  flex-direction: column;
  margin: .25rem 0;
  align-items: center;
  border-radius: 1vh;
  color: #202123;
  font-family: Raleway,sans-serif;
  font-size: large;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.player-card
{
  min-width: 25%;
  min-height: 15%;
  position: absolute;
  box-sizing: border-box;
  transition-property: background-color;
  transition-duration: .7s;
}

.timer-bar-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1vh; /* Or any height you prefer */
  background-color: #ddd; /* Background of the timer bar (greyed out part) */
  border-radius: 2px; /* Rounded borders on the timer bar */
  border: #ddd solid 1px; /* Add a grey border */
}

/* Foreground of the timer bar (the active part) */
.timer-bar {
  height: 100%;
  transition: width 1s linear; /* Smooth transition for the timer bar */
  border-radius: 1vw; /* Rounded borders on the timer bar */
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}
/* Foreground of the bank time bar*/
.bank-time-bar {
  height: 100%;
  border-radius: 1vw; /* Rounded borders on the timer bar */
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

/* Media query for screens with a maximum width of 992px (typical for mobile devices) */
@media (max-width: 992px) and (orientation: portrait) 
{
  .player-card {
    min-width: 55%;
    min-height: 10.5%;
  }
}

@media (max-width: 992px) and (orientation: landscape)  {
  .player-card {
    min-width: 30%;
    min-height: 20%;
  }
}

.player-card.ready
{
  background-color: rgba(80, 186, 93, 0.8)
}

@keyframes box-shadow-pulsate {
  0%   { box-shadow: 0 0 0 5px rgba(255, 187, 0, 1); }
  50% { box-shadow: 0 0 0 5px rgba(255, 187, 0, 0.2); }
  100% { box-shadow: 0 0 0 5px rgba(255, 187, 0, 1); }
}

.player-card.active
{
  animation: box-shadow-pulsate 2s infinite;
}

@keyframes winner-pulsate {
  0%   { background-color: rgba(255, 255, 255, .8); }
  50% { background-color: rgba(248, 221, 12, 0.8); }
  100%   { background-color: rgba(255, 255, 255, .8);}
}

.player-card.winner
{
  animation: winner-pulsate 2s infinite;
}

@keyframes loser-pulsate {
  0%   { background-color: rgba(255, 255, 255, .8); }
  50% { background-color: rgba(226, 0, 0, 0.5); }
  100%   { background-color: rgba(255, 255, 255, .8);}
}

.player-card.loser
{
  animation: loser-pulsate 2s infinite;
}

@keyframes calza-winner-pulsate {
  0%   { background-color: rgba(255, 255, 255, .8); }
  50% { background-color: rgba(4, 0, 226, 0.5); }
  100%   { background-color: rgba(255, 255, 255, .8);}
}

.player-card.calza-winner
{
  animation: calza-winner-pulsate 2s infinite;
}

.player-card.inactive
{
  opacity: .6;
}

#playerCard0
{
  bottom: 5%;
  right: 0%;
}
#playerCard1
{
  bottom: -8%;
  transform: translateX(-50%);
}
#playerCard2
{
  bottom: 5%;
  left: 0%;
}
#playerCard3
{
  left: 0%;
  top: 5%;
}
#playerCard4
{
  top: -8%;
  transform: translateX(-50%);
}
#playerCard5
{
  top: 5%;
  right: 0%;
}

/*media for vertical mobile screen*/
@media (max-width: 992px) and (orientation: portrait) {
  #playerCard1
  {
    bottom: 20%;
    top:auto;
    left: 8%;
    right:auto;
  }
  #playerCard2
  {
    right: auto;
    left: -19%;
    top: auto;
    bottom: 44%;
  }
  #playerCard3
  {
    right: auto;
    top: 18%;
    bottom:auto;
    left: -19%;
  }
  #playerCard4
  {
    left:auto;
    bottom: auto;
    top: -4%;
    right: 0%;
  }
  #playerCard5
  {
    top: 18%;
    right: -19%;
  }
  #playerCard0
  {
    bottom: -4%;
    top:auto;
    left:50%;
    right: auto;
    transform: translateX(-50%);
  }
}

.rotate-text
{
  font-size: xx-large;
  font-weight:800;
}
.player-info
{
  position: absolute;
  flex:1;
  top: 0%;
  left: 0%;
  width: 45%;
  height: 100%;
  border-right: 4%;
  padding: 5%;
  color: #202123;
  font-family: Popins, sans-serif;
  font-weight: bold;
  font-size: calc(8px + .5vw);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.player-name
{
  width:100%;
  /*allow the name to go over into the whitespace in the cup div a little bit, for longer names*/
  white-space: nowrap;
}

.lastBid {
  display: flex;
  align-items: center; /* Vertically center the contents */
  font-size: 2vw; /* Adjust the size as needed */
  transition-property: opacity;
  transition-duration: 0.25s; 
  opacity:.4;
  gap: 2px;
  margin-top: auto;
  margin-bottom: auto;
}

.lastBid > .die
{
  flex: none;
  width: 2vmax;
  height : 2vmax;
}

.lastBid.villain
{
  opacity: 1;
}

.player-card:not(.villain):hover .lastBid {
  opacity: 1;
}

.player-dice
{
  display: flex;
  flex-wrap: wrap;
  position:absolute;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 5% 5% 5% 5%;
  flex-direction: row;
  gap: .2vw;
}

/*media for ultrawide*/
@media (min-width: 2320px) {
  .player-dice
  {
    padding: 5%;
  }
}

.cup-div
{
  position:absolute;
  height: 100%;
  left: 45%;
  width: 55%;
  justify-content: center;
  align-items: center;
}
/*
center vertically and horizontally
*/
.cup
{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3.7vw;
}

/*media for ultrawide*/
@media (min-width: 2320px) {
  .cup
  {
    width: 3.35vw;
  }
}
/*media for mobile porttrait*/
@media (max-width: 992px) and (orientation: portrait) {
  .cup
  {
    width: 11vw;
    top: 50%;
    left: 50%;
  }
}
/*media for mobile landscape*/
@media (max-width: 992px) and (orientation: landscape) {
  .cup
  {
    width: 4.7vw;
  }
}


.cup-text
{
  left: 0;
  position:absolute;
  text-align:center;
  top: 30%;
  width: 100%;
  color: #fff;
  font-size: calc(16px + 1.3vw);
  font-family: Popins, sans-serif;
  font-weight: bold;
  z-index: 1;
}


/* if you update the value of this, you must update PlayerCard render variable dieWidth */
.die {
  flex: 0 0 2vmax;
  /* Adjust the initial size based on container's size */
}

/*media for ultrawide*/
@media (min-width: 2320px) {
  .die
  {
    flex: 0 0 1.7vmax;
  }
}

.inactive
{
  opacity: .5;
}

@keyframes hover-up-down {
  0%   { transform: translateY(-2px); }
  50%   { transform: translateY(0px); }
  100%   { transform: translateY(-2px); }
}
.die.active
{
  animation: hover-up-down 1s infinite;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.newDie {
  opacity:0;
  animation: fadeIn 2s ease-out forwards;
}


@keyframes red-pulsate {
  0%   { outline-color: rgb(255, 0, 0); }
  50% { outline-color: rgba(255, 0, 0, .5); }
  100%   { outline-color: rgba(255, 0, 0, 1); }
}

#table 
{
  display: inline-block;
  position:relative;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0%;
  border-radius: 40vh;
  left: -15%;
  width: 60vw;
  height: 40vw;
}

/*media for ultrawide*/
@media (min-width: 2320px) {
  #table 
  {
    width: 55vw;
    height: 33vw;
  }
}

/*media for mobile*/
@media (max-width: 992px) and (orientation: landscape) {
  #table 
  {
    height: 35vw;
  }
}
@media (max-width: 992px) and (orientation: portrait) {
  #table 
  {
    height:70vh;
    width: 70vw;
    left: 0%;
    transform: translateY(-5%);
  }
}

.center-content
{
  position: absolute; 
  top: 50%;           
  left: 50%;          
  transform: translate(-50%, -50%);
  width:100%;
  min-height: 15%;
}


.game-options
{
  padding: 2vh 2vw;
  
}

.feature-title {
  font-size: 1.3em;
  margin-bottom: 0;
}

.feature-desc {
  color: #53627c;
}

.feature-icon {
  font-size: 2em;
}

@media screen and (min-width: 992px) {
  .feature-card {
    margin: .25rem .5em;
  }
}

.leaderboard
{
  border-collapse: collapse;
  max-width:980px;
	table-layout:fixed;
	margin:auto;
}
.leaderboard tbody
{
  height:44vh;
	overflow:auto;
	overflow-x:hidden;
	display:block;
	width:100%;
}
.leaderboard thead, tfoot {
	display:table;
	width:calc(100% - 12px);
}
.leaderboard tbody > tr
{
  display: table;
  width: 100%;
  table-layout:fixed;
}
.leaderboard-row
{
  border-bottom: 1px solid #7a7575;
}
.leaderboard-row:last-child
{
  border-bottom: none;
}
.leaderboard th, .leaderboard td {
  padding: .5vw .5vh;
  text-align: center;
  width: 25%; /* Adjust as necessary */
}

/* Styles for the scrollbar track (background) */
.leaderboard tbody::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Light gray track */
  border-radius: 10px; /* Rounded corners for the track */
}

/* Styles for the scrollbar handle */
.leaderboard tbody::-webkit-scrollbar-thumb {
  background-color: #888; /* Darker gray handle */
  border-radius: 10px; /* Rounded corners for the handle */
}

/* Handle on hover */
.leaderboard tbody::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Even darker gray on hover */
}

/* Optionally set the width of the scrollbar (default is around 16px) */
.leaderboard tbody::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}


.svg-inline--fa.fa-w-10 {
  width: .625em;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}


:root {
  --primary: 237, 94%, 81%;
  --background: 266, 16%, 92%;
  --background-secondary: 256, 12%, 12%;
  --background-secondary-dark: 256, 10%, 10%;
  --background-secondary-light: 257, 11%, 16%;
  --text-primary: 0, 0%, 0%;
  /* Colors */
  --black: 0, 0%, 0%;
  --white: 0, 0%, 100%;
  --quite-gray: 0, 0%, 50%;
  --grooble: 10, 28%, 93%;
  /* Sizes */
  --heading-large: 5.6rem;
  --heading-medium: 3.6rem;
  --heading-small: 2.4rem;
  --paragraph: 1.11rem;
  --navbar-buttons: 2.4rem;
  /* misc */
  --transition-main: .175, .685, .32;
  /* Fonts */
  --font-main: "Poppins";
}
/* =========
    Flexbox
   ========= */

   .flexbox {
    display: flex;
    justify-content: center;
    align-items: left;
}
.flexbox-left {
    display: flex;
    justify-content: flex-start;
    align-items: left;
}
.flexbox-right {
    display: flex;
    justify-content: flex-end;
    align-items: left;
}
/* Columns */
.flexbox-col {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.flexbox-col-left {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}
.flexbox-col-left-ns {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
}
.flexbox-col-right {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
}
.flexbox-col-start-center {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}
/* Spacings */
.flexbox-space-bet {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* ========
    Navbar
   ======== */
   #navbar {
    top: 0;
    padding: 0;
    width: 64px;
    height: 100vh;
    position: fixed;
    background-color: hsl(var(--background-secondary));
    transition: width .35s cubic-bezier(var(--transition-main), 1);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 5; /* always make the navbar the highest */
}
#navbar:hover {
    width: 15vw;
}
#navbar::-webkit-scrollbar-track {
    background-color: hsl(var(--background-secondary));
}
#navbar::-webkit-scrollbar {
    width: 8px;
    background-color: hsl(var(--background-secondary));
}
#navbar::-webkit-scrollbar-thumb {
    background-color: hsl(var(--primary));
}
.navbar-items {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
/* Navbar Logo */
.navbar-logo {
    margin: 0 0 2em 0;
    width: 100%;
    height: 5em;
    background: hsl(var(--background-secondary-dark));
}

/*hide logo on mobile screen if turned sideways, otherwise show*/
@media screen and (max-width: 992px) and (orientation: landscape) {
  .navbar-logo {
      display: none;
  }
}

.navbar-logo > .navbar-item-inner {
    width: calc(5rem - 8px);
}
.navbar-logo > .navbar-item-inner:hover {
    background-color: transparent;
}
.navbar-logo > .navbar-item-inner > svg {
    height: 2em;
    fill: hsl(var(--white));
}
/* Navbar Items */
.navbar-item {
    padding: 0 .5em;
    width: 100%;
    cursor: pointer;
}
.navbar-item-inner {
    padding: 1em 0;
    width: 100%;
    position: relative;
    color: hsl(var(--quite-gray));
    border-radius: .25em;
    text-decoration: none;
    transition: all .2s cubic-bezier(var(--transition-main), 1);
}
.navbar-item-inner:hover {
    color: hsl(var(--white));
    background: hsl(var(--background-secondary-light));
    box-shadow: 0 17px 30px -10px hsla(var(--black), .25);
}
.navbar-item-inner-icon-wrapper {
    width: calc(5rem - 1em - 8px);
    position: relative;
}
.navbar-item-inner-icon-wrapper ion-icon {
    position: absolute;
    font-size: calc(var(--navbar-buttons) - 1rem);
}
.link-text {
    margin: 0;
    width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all .35s cubic-bezier(var(--transition-main), 1);
    overflow: hidden;
    opacity: 0;
}
#navbar:hover .link-text {
    width: calc(100% - calc(5rem - 8px));
    opacity: 1;
}

#navbar:hover .profile-name {
  width: calc(100% - calc(5rem - 8px));
  opacity: 1;
}

.profile-name
{
  margin-top: 1vh;
  font-size: calc(var(--navbar-buttons) - 1rem);
  font-weight: bold;
  margin-left: 1vw;
  color: hsl(var(--quite-gray));
  white-space: nowrap;
  overflow: hidden;
  opacity: 0;
  transition: all .35s cubic-bezier(var(--transition-main), 1);
}

.profile-name-visible {
  opacity: 1;
  /* No transition property here, so it doesn't apply when going from visible to hidden */
}

.navbar-item.logout
{
  position: absolute;
  bottom: 0;
}

#hamburger-menu, #btn-full-screen, #btn-toggle-chat {
  display: none; /* Hide hamburger menu on desktop */
}

/* Mobile */
@media screen and (max-width: 992px) { /* Adjust the max-width as per your breakpoint */
  #navbar {
      /* Styles to initially hide the sidebar on mobile */
      width:0vw;
  }
  #navbar:hover {
    width: 0vw;
}

  #navbar.navbar-visible {
    width: 80vmin; /* Width when navbar is visible */
  }
  .profile-name
  {
    width: calc(100% - calc(5rem - 8px));
    opacity: 1;
  }
  .link-text {
    width: calc(100% - calc(5rem - 8px));
    opacity: 1;
  }

  #hamburger-menu, #btn-full-screen, #btn-toggle-chat {
      display: block; /* Show hamburger menu on mobile */
  }
}


/* Media query for mobile screens */
@media screen and (max-width: 992px) and (orientation: landscape) {
  /* Targeting the logout button specifically */
  .navbar-item.logout {
      position: static; /* Override absolute positioning */
      bottom: auto; /* Reset bottom positioning */
  }
}